.container {
    display: flex;
}

.list {
    margin-right: 10px;
    flex-grow: 1;
    align-self: flex-start;
    max-width: 200px;
    position: -webkit-sticky;
    position: sticky;
    top: 25px;
}

.singleChar {
    flex-grow: 2;
    padding: 0 15px;
}

.comment {
    margin-bottom: 10px;
}

@media screen and (min-width: 1600px) {
    .list {
        top: 120px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        flex-direction: column;
    }
    .list {
        margin-right: 0;
        max-width: 100%;
        position: static;
        align-self: stretch;
    }
}
